import { createSlice } from '@reduxjs/toolkit';

interface initState {
  ai_image_active: number;
  change_image_bg_active: number;
  creativity_video_active: number;
}

const initialState: initState = {
  ai_image_active: 0,
  change_image_bg_active: 0,
  creativity_video_active: 0,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // ai生图的历史侧边栏激活
    setAiImageActive: (state, action) => {
      state.ai_image_active = action.payload;
    },
    // ai换背景的历史侧边栏激活
    setChangeImageBgActive: (state, action) => {
      state.change_image_bg_active = action.payload;
    },
    //创意视频的历史侧边栏激活
    setCreativityVideoActive: (state, action) => {
      state.creativity_video_active = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setAiImageActive, setChangeImageBgActive, setCreativityVideoActive } =
  commonSlice.actions;

export default commonSlice.reducer;
