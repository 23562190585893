import { Menu, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setMenus } from '@/store/features/init';
import { store } from '@/store';
import { routes } from '@/config/routes';
import { faceOpenPermissionCid } from '@/utils/config';
import beta from '@/assets/beta.png';
import { useAppSelector } from '@/store/hooks';
import { isOriginSkyink } from '@/utils/index';
import './index.less';

type MenuItem = Required<MenuProps>['items'][number];

// 待用未完成动态Menu
//const processRoute = (routes: any, result: any) => {
//  routes.forEach((item: any ) => {
//    const temp: any = {
//      key: item.path,
//      icon: '',
//      label: item.title,
//    };
//    console.log('temp',temp);
//    result.push(temp);
//    if (item?.children?.length) {
//      temp.children = [];
//      processRoute(item.children, temp.children);
//    }
//  });
//};

type menuProps = {
  collapsed?: boolean;
};

const SideMenu: FC<menuProps> = (props) => {
  const navigateTo = useNavigate();
  const currentRoute = useLocation();
  // 公司角色 role_type 0=个人角色 1=管理者 2=使用者
  const accountInfo: any = useAppSelector((state) => state.init.account_info);
  const { collapsed = false }: any = props;
  const [items, setItems] = useState<MenuItem[]>([]);
  /**
   * 当账号是 1、企业用户的使用者、2、个人用户的时候  不显示的路由
   */
  const noShowMenu: any[] = ['/user/sub_account'];
  /**
   * 当账号是 个人用户的时候  不显示的路由
   */
  const noShowFaceMenu: any[] = ['/faceswap/change'];

  /**
   * 刷新
   */
  const refresh = () => {
    /**
     * 处理菜单数据 用于显示菜单栏
     * 最多只有三层 第一层不处理
     */
    let tempItems: any = [];
    if (routes && routes.length > 0) {
      routes.forEach((el: any) => {
        // 第一层数据
        if (el.children) {
          // 是否有子项
          el.children.forEach((it: any) => {
            let tempObject: any = {};
            // 第二层
            if (it.isMenu) {
              if (noShowMenu.includes(it.path) && accountInfo.role_type !== 1) {
              } else {
                // 是否菜单
                tempObject = {
                  label: it.name,
                  key: it.path,
                  icon: it.icon,
                  title: it.title || (isOriginSkyink() ? '云端妙笔' : '鲸智云'),
                };
              }
            }
            if (it.children) {
              tempObject.children = [];

              // 是否有子项
              it.children.forEach((item: any) => {
                // 第三层
                if (item.isMenu) {
                  if (
                    (noShowMenu.includes(item.path) && accountInfo.role_type !== 1) ||
                    (noShowFaceMenu.includes(item.path) &&
                      (!accountInfo.role_type ||
                        !faceOpenPermissionCid.includes(accountInfo.company_id)))
                  ) {
                  } else {
                    // 是否菜单
                    tempObject.children.push({
                      label:
                        item.name === '图生图' ? (
                          <span className="imageToImage">
                            {item.name}{' '}
                            <img
                              src={beta}
                              alt="beta"
                              className="beta"
                              style={{ width: !collapsed ? '30px' : '' }}
                            />
                          </span>
                        ) : (
                          item.name
                        ),
                      key: item.path,
                      icon: item.icon,
                      title: item.title || '鲸智云',
                    });
                  }
                }
              });
            }
            if (tempObject.label) {
              tempItems.push(tempObject);
            }
          });
        }
      });
    }
    setItems(tempItems);

    /**
     * 处理菜单数据 用于显示面包屑
     */
    let menus: any[] = [];
    if (routes && routes.length > 0) {
      routes.forEach((el: any) => {
        // 第一层数据
        if (!el.children) {
          menus[el.path] = [el];
        } else {
          el.children.forEach((it: any) => {
            // 第二层数据
            menus[it.path] = [it];
            if (it.children) {
              it.children.forEach((item: any) => {
                menus[item.path] = [it, item];
              });
            }
          });
        }
      });
    }
    store.dispatch(setMenus(menus));
  };

  useEffect(() => {
    refresh();
  }, [routes, accountInfo]);

  // 菜单点击
  const menuClick = (e: { key: string }) => {
    navigateTo(e.key);
  };

  //拿着currentRoute.pathname跟items数组的每一项的children的key值进行对比,如果找到了相等,
  //就要他上一级的key,这个key给到openKeys数组的元素，作为初始值
  let firstOpenKey = '';
  function findKey(obj: { key: string }) {
    return obj.key === currentRoute.pathname;
  }
  // 对比的是多个children
  function findFirstOpenKey() {
    for (let i = 0; i < items.length; i++) {
      let itemT: any = items[i];
      if (itemT!['children'] && itemT!['children'].length > 0 && itemT!['children'].find(findKey)) {
        firstOpenKey = itemT!.key as string;
        break;
      }
    }
  }
  //设置展开项的初始值
  const [openKeys, setOpenKeys] = useState([firstOpenKey]);
  const handleOpenChange = (keys: string[]) => {
    setOpenKeys([keys[keys.length - 1]]);
  };

  useEffect(() => {
    findFirstOpenKey();
    setOpenKeys([firstOpenKey]);
  }, [currentRoute.pathname, items]);

  return (
    <Menu
      className="sider-menu"
      selectedKeys={[currentRoute.pathname]}
      mode="inline"
      theme="light"
      items={items}
      onClick={menuClick}
      onOpenChange={handleOpenChange}
      openKeys={openKeys}
    />
  );
};
export default SideMenu;
