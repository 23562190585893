import { createSlice } from '@reduxjs/toolkit';

interface initState {
  image_reference: string;
  text2ImgUnionId: string;
  img2ImgUnionId: string;
}

const initialState: initState = {
  image_reference: '',
  text2ImgUnionId: '',
  img2ImgUnionId: '',
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setImageReference: (state, action) => {
      state.image_reference = action.payload;
    },
    setText2ImgUnionId: (state, action) => {
      state.text2ImgUnionId = action.payload;
    },

    setImg2ImgUnionId: (state, action) => {
      state.img2ImgUnionId = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setImageReference, setText2ImgUnionId, setImg2ImgUnionId } = imageSlice.actions;

export default imageSlice.reducer;
