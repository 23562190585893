import { LeftOutlined, RightOutlined, UserOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Carousel, Modal, Dropdown, Avatar } from 'antd';
import classnames from 'classnames';
import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './index.less';
import logoYDMB from '@/assets/logo-ydmb.svg';
import logo from '@/assets/logo.svg';
import QRYDMB from '@/assets/qr-ydmb.png';
import QR from '@/assets/qr.png';
import xiaohongshuPic from '@/assets/whale/3.png';
import logoBYDMO from '@/assets/whale/logo-ydmb.png';
import logoB from '@/assets/whale/logo.png';
import planet from '@/assets/whale/planet.png';
import LoginRegisterModal from '@/components/Header/components/loginRegisterModal';
import { logout } from '@/config/api/login';
import { useAppSelector } from '@/store/hooks';
import { clearUserInfo, fetchUserInfo, isOriginSkyink } from '@/utils';
import { WHALE_WISDOM_CLOUDS_TOKEN, URL_EXPEND, INVITE_TOKEN } from '@/utils/config';
import ls from '@/utils/storage';

const skyinkFlag = isOriginSkyink();
const originStr = skyinkFlag ? '云端妙笔' : '鲸智云';
const originQR = skyinkFlag ? QRYDMB : QR;
const companyStr = skyinkFlag ? '云想科技(HK.02131)' : '上海软赢科技';

const bannerList = [
  {
    bg: 'Banner_ydmb_1',
    text_1: '定制专属内容',
    text_2: `${originStr}为用户量身打造`,
    subTitle: '内容优产，稳定输出',
  },
  {
    bg: 'Banner_ydmb_2',
    text_1: '写文不愁',
    text_2: `${originStr}助力PUGC冲榜热搜`,
    subTitle: '轻松上首页',
  },
  {
    bg: 'Banner_ydmb_3',
    text_1: originStr,
    text_2: '小红书文案创作的得力助手',
    subTitle: '一键生成，轻松仿写',
  },
];

const redBookTextTitle = ['AI文案助手', '文件加Emoji', '文案仿写', '爆款标题'];
const redBookTextList = [
  '一步操作，生成文案',
  '文案自动添加表情符号',
  '爆款文案深度提炼再创作',
  '上热搜快人一步',
];

const { confirm } = Modal;
const WhaleClouds = () => {
  const navigate = useNavigate();
  const loaction = useLocation();
  //const [searchParams] = useSearchParams();
  //const accountInfos: any = useAppSelector((state) => state.init.account_info);
  const userInfo: any = useAppSelector((state) => state.init.info);

  const myRefCarousel: any = useRef(null);
  const refLoginRegisterModal = useRef(null);

  useEffect(() => {
    // 获取扩展参数
    if (!loaction.search) return;
    const param = new URLSearchParams(loaction.search);
    const obj: any = {};
    for (const item of param.entries()) {
      obj[item[0]] = item[1];
    }
    // 当链接带参数的时候  会重新记录数据
    if (JSON.stringify(obj) !== '{}') {
      if (obj['invite_token']) {
        ls.set(INVITE_TOKEN, obj['invite_token'] || '');
        delete obj.invite_token;
      } else {
        ls.set(INVITE_TOKEN, undefined);
      }
      ls.set(URL_EXPEND, JSON.stringify(obj) !== '{}' ? JSON.stringify(obj) : undefined);
    }
  }, []);

  async function handleToCreativity() {
    const token = ls.get(WHALE_WISDOM_CLOUDS_TOKEN);
    if (token) {
      await fetchWhaleUserInfo();
    } else {
      // 需要登录
      (refLoginRegisterModal.current as any).init(skyinkFlag);
    }
  }

  // 获取用户信息
  async function fetchWhaleUserInfo() {
    const token = ls.get(WHALE_WISDOM_CLOUDS_TOKEN);
    if (!token) return;
    fetchUserInfo('login');
  }

  /**
   * 退出
   */
  function handleLogout() {
    confirm({
      icon: <InfoCircleFilled />,
      title: '退出',
      maskClosable: false,
      style: { top: 250 },
      className: 'agreementModal',
      content: (
        <div>
          <p>您确定要退出登录吗？</p>
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        logout().then(() => {
          clearUserInfo();
          window.location.replace('/welcome');
        });
      },
    });
  }
  const items = [
    {
      key: '4',
      label: (
        <p onClick={handleLogout} style={{ textAlign: 'center' }}>
          退出
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="pageContentHome">
        <div className="headerContainerHome">
          <div className="headerLeftContent">
            <img
              className="logoImage"
              src={skyinkFlag ? logoYDMB : logo}
              alt="head-logo"
              onClick={() => navigate('/welcome')}
            />
          </div>
          <div className="headerRightContent">
            {ls.get(WHALE_WISDOM_CLOUDS_TOKEN) ? (
              <>
                <Dropdown menu={{ items }} placement="bottomLeft">
                  <div>
                    <Avatar
                      className="avatar"
                      src={userInfo?.avatar || undefined}
                      icon={<UserOutlined />}
                    />
                    <span>{userInfo?.nickname || userInfo?.name}</span>
                  </div>
                </Dropdown>
              </>
            ) : (
              <div>
                <span onClick={handleToCreativity} className="rightContentBtn">
                  登录 / 注册
                </span>
              </div>
            )}
          </div>
        </div>
        <section className="bannerContainer">
          <div
            className="btnLeft"
            onClick={() => {
              myRefCarousel?.current?.prev?.();
            }}
          >
            <LeftOutlined />
          </div>
          <div
            className="btnRight"
            onClick={() => {
              myRefCarousel?.current?.next?.();
            }}
          >
            <RightOutlined />
          </div>
          <Carousel ref={myRefCarousel} effect="fade" autoplay>
            {bannerList.map((item: any) => {
              return (
                <div className={classnames('itemBanner', `${item.bg}`)} key={item.text_1}>
                  <div className="itemBannerText">
                    <h3>{item.text_1}</h3>
                    <h3>{item.text_2}</h3>
                    <p>{item.subTitle}</p>
                    <Button size="large" onClick={handleToCreativity}>
                      立即使用
                    </Button>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </section>

        {/*   文案创作模块   */}
        <section
          className={classnames('copyWriting', {
            copyskyinkFlag: skyinkFlag,
          })}
        >
          <div className="textContent">
            <h3>{originStr}，让文案创作更美妙</h3>
            <p>
              <span>人工智能与自然语言处理技术完美融合，</span>
              <span>为用户提供无与伦比的写作体验。</span>
            </p>
          </div>
        </section>

        {/*    小红书   */}
        <section className="redBook">
          <div className="titleContent">
            <h3>小红书写作文案</h3>
            <p>运营必备，轻松打造爆款文案</p>
          </div>
          <div className="textContent">
            <div className="leftContent">
              <ul className="title">
                {redBookTextTitle.map((item: string, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
              <ul className="subTitle">
                {redBookTextList.map((item: string, index) => {
                  return (
                    <li key={index} style={{ marginTop: index === 2 ? '126px' : '' }}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="rightContent">
              <div className="groundFloor">
                <img src={xiaohongshuPic} alt="" />
              </div>
            </div>
          </div>
          <div className="block">
            <p className="greenBlock" />
            <p className="blueBlock" />
          </div>
        </section>

        {/*    客户案例   */}
        <section className="customerCase">
          <div className="titleContent">
            <h3>客户案例</h3>
            <p>帮助企业用户降本增效</p>
          </div>
          <div className="customerContent">
            <img src={planet} alt="" />
            <div className="grayBlock" />
            <div className="left">
              <h3>某社交行业创作团队</h3>
              <p className="explain">
                某内容创作团队，人员10名，服务客户50家，媒体矩阵号100个，客户对内容质量要求高，数量多，每日花费大量时间选题和定稿，沟通成本和人力成本高。
              </p>
              <p className="subExplain">帮助客户实现</p>
              <ul>
                <li>
                  <div>
                    5000 <span>条+</span>
                  </div>
                  <p>优质内容</p>
                </li>
                <li>
                  <div>
                    3800 <span>条+</span>
                  </div>
                  <p>千赞爆文</p>
                </li>
                <li>
                  <div>
                    600 <span>%+</span>
                  </div>
                  <p>人效提升</p>
                </li>
              </ul>
            </div>
            <div className="right">
              <h3>某母婴行业创作团队</h3>
              <p>帮助客户实现</p>
              <ul>
                <li>
                  <div>
                    2000 <span>条+</span>
                  </div>
                  <p>优质内容</p>
                </li>
                <li>
                  <div>
                    1450 <span>条+</span>
                  </div>
                  <p>千赞爆文</p>
                </li>
                <li>
                  <div>
                    20 <span>万+</span>
                  </div>
                  <p>带货GMV</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="block">
            <p className="greenBlock" />
            <p className="blueBlock" />
          </div>
        </section>

        {/*关于我们*/}
        <section className="aboutUs">
          <div className="container">
            <div className="titleContent">
              <h3>关于我们</h3>
              <p>赋能营销的智能创作工具</p>
            </div>
            <div className="dialogbox">
              <p>
                <span>
                  {originStr}是{companyStr}
                  在数字营销领域的研发和应用成果。它成功集成了大数据建模和平台化技术优势，通过应用人工智能生成内容（AIGC）技术，在图文和视频领域实现了一系列开发应用，包括创意文案，小红书营销文案和视频脚本。
                </span>
                <span>
                  我们致力于打造全球领先的人工智能驱动的文本创作平台。我们相信，越来越多的企业和个人用户将从
                  {originStr}中受益，并在未来的创作旅程中，创造更多的可能性。
                </span>
              </p>
            </div>
            <div className="portraitBlock">
              <div className="grayBlock" />
              <div className="blueBlock" />
            </div>
            <div className="crossBlock" />
          </div>
        </section>

        <section className="more">
          <div>
            <h3>更多功能，等你解锁</h3>
            <Button size="large" onClick={handleToCreativity} block>
              立即使用
            </Button>
          </div>
        </section>

        <div className="footerContainer">
          <div className="footerBottom">
            <div className="logo">
              <img src={skyinkFlag ? logoBYDMO : logoB} alt="" />
              <div className="contact">
                <div>
                  <p className="contactTitle">电话</p>
                  <p>13052585817</p>
                </div>
                <div>
                  <p className="contactTitle">邮箱</p>
                  <p>{skyinkFlag ? 'lvkan@netjoy.com' : 'kaka@brainywhale.com'}</p>
                </div>
              </div>
            </div>
            <div className="qr">
              <img src={originQR} alt="" />
            </div>
          </div>
          <div className="bottomText">
            <p>
              Copyright{' '}
              {skyinkFlag ? '©2012-2023 云想科技控股有限公司' : '©2022-2024 上海软赢科技有限公司'}
            </p>
            <p>沪ICP备2024052277号-1</p>
          </div>
        </div>
      </div>

      <LoginRegisterModal ref={refLoginRegisterModal} />
    </>
  );
};

export default WhaleClouds;
