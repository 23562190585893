import { message as $message } from 'antd';

import ls from './storage';

import { getUserInfo, mb_expand } from '@/config/api/login';
import { passwordStatus } from '@/config/api/user';
import { store } from '@/store';
import { setVideoInfo } from '@/store/features/faceswap';
import {
  setUserInfo,
  setAccountInfo,
  setMessageInfo,
  setCompanyInfo,
  setPwdStatus,
} from '@/store/features/init';
import { WHALE_WISDOM_CLOUDS_TOKEN, URL_EXPEND, INVITE_TOKEN, COMPANY_ID } from '@/utils/config';
import eventBus, { OPEN_VIP_MODAL } from '@/utils/events';

// api
// 获取用户信息
export async function fetchUserInfo(type?: string) {
  const token = ls.get(WHALE_WISDOM_CLOUDS_TOKEN);
  if (!token) return;
  await getUserInfo({})
    .then(async (res) => {
      const { data, code, msg } = res;
      if (code !== 0) {
        if (res.code === 2164260881) {
          return false;
        }
        $message.error(msg);
        return;
      }
      if (data?.account_info)
        ls.set(COMPANY_ID, {
          uid: data?.account_info.user_id,
          cid: data?.account_info.company_id,
        });
      store.dispatch(setUserInfo(data.user_info));
      store.dispatch(setAccountInfo(data.account_info));
      store.dispatch(setCompanyInfo(data.company_info));
      store.dispatch(setMessageInfo(data.message_info));
      const expand = ls.get(URL_EXPEND);
      const invite_token = ls.get(INVITE_TOKEN);
      if (window.location.pathname.includes('/welcome')) {
        if (expand || invite_token) {
          await mb_expand({ expand, invite_token });
          ls.set(URL_EXPEND, undefined);
          ls.set(INVITE_TOKEN, undefined);
        }
      }
      // is_new === 1 则为第一次登录 第一次登录跳转信息补全页面
      if (data.user_info.is_new && data.user_info.is_new === 1) {
        window.location.replace('/addInfo');
      }
      if (data?.user_info?.check_account !== 1 && data?.user_info?.is_new !== 1) {
        eventBus.emit(OPEN_VIP_MODAL, 'choose');
      }
      if (type === 'login') {
        window.location.replace('/home');
      }
    })
    .catch((err) => {
      $message.error(err.message);
    });
}

// 清空用户信息
export function clearUserInfo() {
  store.dispatch(setUserInfo({}));
  store.dispatch(setAccountInfo({}));
  store.dispatch(setCompanyInfo({}));
  store.dispatch(setMessageInfo({}));
  store.dispatch(setVideoInfo({}));
  ls.clear();
}

// 用户密码状态
export async function getPasswordStatus() {
  await passwordStatus()
    .then((res) => {
      if (res.code !== 0) {
        return $message.error(res.msg);
      }
      store.dispatch(setPwdStatus(res.data.status));
    })
    .catch((err) => {
      $message.error(err.message);
    });
}

/**
 * 计算两个日期差
 * @param date
 * @param date2
 */
export const computerDay = (date: any, date2?: any) => {
  const t1 = Date.parse(`${date} 23:59:59`);
  const dd = new Date();
  const t2 = date2 ? Date.parse(date2) : Date.parse(dd.toString());
  const totalDays = Math.floor((t1 - t2) / (1000 * 3600 * 24)) + 1;
  return totalDays > 0 ? totalDays : 0;
};

/**
 * 手机号脱敏
 * @param phone 手机号
 */
export const desensitizationPhone = (phone: string) => {
  if (null !== phone && phone !== undefined) {
    const pat = /(\d{3})\d*(\d{4})/;
    return phone.replace(pat, '$1****$2');
  } else {
    return '';
  }
};

/**
 *  map -> list
 * @param map
 * @returns {*[]}
 */
export const mapToList = (map: any) => {
  const list: any[] = [];
  Object.keys(map).forEach((key) => {
    list.push({
      value: key,
      label: map[key],
    });
  });
  return list;
};

/**
 *  list -> map
 * @param list
 * @returns {*{}}
 */
export const listToMap = (list: any[]) => {
  const map: any = {};
  list.forEach((item) => {
    map[item.value] = item.label;
  });
  return map;
};

/**
 * 文件类型对应blob类型
 */
export function getBlobType(url: string): string {
  const type = url?.substring(url?.lastIndexOf('.')).toLocaleLowerCase();
  switch (type) {
    case '.xls':
      return 'application/vnd.ms-excel';
    case '.xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case '.doc':
      return 'application/msword';
    case '.docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case '.csv':
      return 'text/csv';
    case '.pdf':
      return 'application/pdf';
    case '.txt':
      return 'text/plain';
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
    case '.png':
      return 'image/png';
    case '.webp':
      return 'image/webp';
    case '.bmp':
      return 'image/bmp';
    case '.gif':
      return 'image/gif';
    case '.tiff':
    case '.tif':
      return 'image/tiff';
    case '.svf':
      return 'vnd.svf';
    case '.rar':
      return 'application/x-rar-compressed';
    case '.zip':
      return 'application/zip';
    case '.mp4':
      return 'video/mp4';
    case '.avi':
      return 'video/x-msvideo';
    case '.mov':
      return 'video/quicktime';
    default:
      //return 'application/vnd.ms-excel';
      return '';
  }
}
/**
 * blob下载文件
 */
export function downloadFile(url: string, fileName?: string, type?: string): void {
  if (!url) return;
  fetch(url)
    .then((response) => response.blob())
    .then((res) => {
      if (!res) {
        return;
      }
      const blobUrl = window.URL.createObjectURL(
        new Blob([res], { type: type || getBlobType(url) })
      );
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.style.display = 'none';
      link.download = fileName || '';
      link.href = blobUrl;
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    });
}

/**
 * 获取{{{}}}中的内容文本
 * @param content 需要校验的文本
 */
export const getContentString = (content: string) => {
  if (null !== content && content !== undefined) {
    const pat = /\{\{\{(\S[^abc]*)\}\}\}/;
    return content.match(pat)?.[1];
  } else {
    return '';
  }
};

/**
 * 新窗口打开
 * @param url 需要打开的url
 */
export function windowNewOpen(url: string): void {
  const a: any = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('style', 'display:none');
  a.setAttribute('target', '_blank');
  document.body.appendChild(a);
  a.click();
  a.parentNode.removeChild(a);
  const timer = setTimeout(() => {
    window.location.reload();
    timer && clearTimeout(timer);
  }, 10);
}

export const isMobile = (phoneNum: string) => {
  if (!phoneNum) console.log('请输入手机号');
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(phoneNum);
};

/**
 * 返回去除所有 Markdown 标记的纯文本
 * @param markdownText  markdown字符串内容
 */
export const removeMarkdownFormatting = (markdownText: any) => {
  // 去除标题标记
  const noHeaders = markdownText.replace(/^#+/gm, '');
  // 去除列表标记
  const noLists = noHeaders.replace(/^- +|  \* /gm, '');
  // 去除其他可能的 Markdown 格式，比如斜体、粗体等
  // 注意：这只是一个简化的例子，Markdown 的语法非常复杂，完全去除可能需要更复杂的正则表达式或解析器
  const noFormatting = noLists.replace(/[*_`~<>]+/g, '');
  // 去除多余的空白行和空格
  const trimmedText = noFormatting.trim().replace(/\n{2,}/g, '\n');
  return trimmedText;
};

/**
 * 判断一个数组是否为空字符串数组
 * @param arr 需要判断的传入数组
 * @returns
 */
export const isEmptyStringArray = (arr: any[]) => {
  return Array.isArray(arr) && arr.every((item) => typeof item === 'string' && !item.trim());
};

/**
 * 将blob图片地址转为base64
 * @param blob blob格式转换为base64格式
 * @param callback 回调 转换后的数据
 */
export const blobToDataURI = (blob: any, callback: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    callback(e.target?.result);
  };
};

/**
 * 将blob转为file文件
 * @param blob blob数据
 * @param fileName 文件名称
 * @param fileType 文件类型
 * @returns 返回的file文件
 */
export const blobToFile = (blob: any, fileName: string, fileType: any) => {
  return new File([blob], fileName, { type: fileType, lastModified: Date.now() });
};

/**
 * 生成10位数的随机字符串 包含字母和英文
 * @returns 返回的随机10位数的随机字符串
 */
export const generateRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

/**
 * 文件的后缀
 * @param fileName 当前上传的文件全名
 * @returns 返回的文件的后缀
 */
export const findFileExtensionExtend = (fileName: string) => {
  const arr = fileName.split('.');
  return arr[arr.length - 1] || 'jpg';
};

/**
 * 数字补零
 * @param num： 被操作数
 * @param n： 固定的总位数
 */
export function prefixZero(num: number | string, n = 2) {
  //console.log('prefixZero', (Array(n).join('0') + num).slice(-n));
  return (Array(n).join('0') + num).slice(-n);
}
/**
 * realTime 秒 -> 分：秒
 */
export function realTime2MinuteTime(realTime: number): string {
  if (!realTime) return '00:00';
  const s = Math.floor(realTime / 1000); // 秒
  const m = Math.floor(s / 60); // 分
  return `${prefixZero(m % 60)}:${prefixZero(s % 60)}`;
}
/**
 * realTime -> 时：分：秒
 */
export const realTime2Time = (realTime: number): string => {
  if (!realTime) return '00:00:00';
  const s = Math.floor(realTime / 1000); // 秒
  const m = Math.floor(s / 60); // 分
  const h = Math.floor(m / 60); // 时
  return `${prefixZero(h)}:${prefixZero(m % 60)}:${prefixZero(s % 60)}`;
};
/**
 * realTime -> 分：秒：毫秒
 */
export function realTimeToMilliTime(realTime: number): string {
  //const realS = realTime / 1000;
  if (!realTime) return '00:00:00';
  const s = Math.floor(realTime / 1000); // 秒
  const m = Math.floor(s / 60); // 分
  const temp = Math.ceil(realTime / 10) / 100; // 毫秒向上取整
  const realTimeArr = String(temp).split('.') || undefined;
  return `${prefixZero(m % 60)}:${prefixZero(s % 60)}:${
    realTimeArr[1] === undefined ? '00' : adjustStringLength(realTimeArr[1])
  }`;
  //return `${prefixZero(m % 60)}:${prefixZero(s % 60)}:${prefixZero(realTime)}`;
}

export function adjustStringLength(str: string) {
  // 如果字符串长度小于2，则补0至长度为2
  if (str.length < 2) {
    // 创建一个长度为2 - str.length的0字符串，并连接到str上
    return str + '0'.repeat(2 - str.length);
  }
  // 如果字符串长度大于或等于2，则截取前两个字符
  return str.slice(0, 2);
}

/**
 * 视频时长平均分
 * @param totalSeconds 视频总时长
 * @param numberOfParts 分成的部分数
 * @returns 返回的视频时长均分
 */
export const divideVideoIntoParts = (totalSeconds: number, numberOfParts: number) => {
  // 计算每个部分的时长
  //const partDuration = Math.floor(totalSeconds / numberOfParts);
  const partDuration = (totalSeconds / numberOfParts).toFixed(3);
  // 计算余数
  //const remainder = totalSeconds % numberOfParts;
  // 初始化每个部分的时长数组
  const partDurations = [];
  for (let i = 0; i < numberOfParts; i++) {
    // 对于前remainder个部分，增加1秒以处理余数
    //if (i < remainder) {
    //	partDurations.push(i * partDuration + partDuration + 1);
    //} else {
    //	partDurations.push(i * partDuration + partDuration);
    //}
    partDurations.push(i * Number(partDuration));
  }

  return partDurations;
};

/**
 * 预览url的拼接
 * @param region 当前坐标位置
 * @returns
 */
export const previewUrlSpliceStr = (region: any) => {
  //region中的数组分别对应：起始点的x坐标[0] /y坐标[1] /宽度[2] /高度[3]
  //计算人脸
  //const newWidth = region[2] * 3;
  //const newHeight = region[3] * 3;
  const newX = Math.max(0, region[0] - region[2]);
  const newY = Math.max(0, region[1] - region[3]);
  const newWidth = (region[0] - newX) * 2 + region[2];
  const newHeight = (region[1] - newY) * 2 + region[3];
  // 调整新的人脸框并使其不超出图像边界
  return `imageMogr2/crop/!${newWidth}x${newHeight}a${newX}a${newY}`;
};

/**
 * 换脸数据二维数组扁平化
 * @param data 需要处理的换脸数据
 */
export const facesWapFlatData = (data: any) => {
  const temp =
    data.map((item: any) => {
      const { region, landmarks_str, preview_urls, url, id } = item;
      return landmarks_str.map((_: any, idx: number) => {
        return {
          path: url,
          preview_url: preview_urls?.[idx]
            ? preview_urls[idx]
            : `${url}|${previewUrlSpliceStr(region[idx])}`,
          opts: _,
          id,
        };
      });
    }) || [];
  return temp.flat();
};

// 过滤函数，仅保留change_text和voice_id在array2中都找不到相同匹配项的对象
export const filterUnique = (array1: any[], array2: any[]) => {
  return array1.filter((item1: any) => {
    // 使用some来检查array2中是否存在相同text和vid的对象
    return !array2.some(
      (item2: any) => item2.change_text === item1.change_text && item2.voice_id === item1.voice_id
    );
  });
};

// form -item 检测首尾空格
export const checkBeginAndEndSpace = (_: any) => {
  return _.target.value.replace(/(^\s*)|(\s*$)/g, '');
};

// 是否是妙笔的域名
export const isOriginSkyink = () => {
  return window.location.origin.includes('skyink');
};

// 查找二维数组中，数组length最长的那组数据
export const findLongestArray = (arr: any) => {
  // 使用reduce来遍历数组，同时累积找到的最长数组
  return arr.reduce((longest: any, current: any) => {
    // 如果当前数组的长度大于已知的最长数组的长度
    if (current.length > longest.length) {
      // 更新最长数组
      return current;
    }
    // 否则，保持原最长数组
    return longest;
  }, []); // 初始值设置为一个空数组，因为在reduce中需要一个初始值
};

/**
 * 获取input光标位置
 */
export const getPosition = function (element: HTMLInputElement | HTMLTextAreaElement): number {
  if (element.selectionStart !== undefined) {
    // 对于大多数现代浏览器，直接返回 selectionStart
    return element.selectionStart || 0;
  } else {
    // 对于老旧的IE浏览器（IE8及以下），这里需要其他逻辑，但通常不推荐支持这些浏览器
    // 因为IE8及以下不支持现代Web标准，也没有有效的跨浏览器方法来模拟 selectionStart
    // 如果确实需要支持这些浏览器，可能需要引入polyfill或使用其他库
    throw new Error('This browser does not support getting the cursor position.');
  }
};

/**
 * 视频名称正则
 */
export const videoTitleReg = function (filedArr: any[]) {
  let s = '';
  filedArr.forEach((str, i) => {
    if (i < filedArr.length - 1) {
      s = `${s}\\{${str}\\}` + `|`;
    } else {
      s = `${s}\\{${str}\\}`;
    }
  });
  const reg = new RegExp(s, 'g');
  return reg;
};

//将颜色值改为16进制数值 即#改为0x
export const convertToHexNumberWithPrefix = (hexColor: string) => {
  //// 验证输入是否为有效的十六进制颜色字符串
  //if (!/^#[0-9A-Fa-f]{6}$/.test(hexColor)) {
  //  throw new Error('Invalid hex color format');
  //}

  //// 去除#前缀并转换为数值
  //const numericValue = parseInt(hexColor.slice(1), 16);

  //// 返回表示该数值的字符串，前面加上'0x'
  //return `0x${numericValue.toString(16).toUpperCase().padStart(6, '0')}`;

  //// 注意：这里直接返回`0x`前缀的字符串表示，而不是一个真正的数值类型。
  //// 因为JavaScript中的数值类型不支持直接以'0x'前缀的形式存在。
  //// 实际上，返回的字符串只是为了满足特定的表示需求。
  // 确保输入是一个字符串
  if (typeof hexColor !== 'string') {
    throw new TypeError('hexColor must be a string');
  }

  // 去除可能的'#'前缀
  hexColor = hexColor.replace(/^#/, '');

  // 如果颜色值长度小于8（不包含透明度），则认为是纯颜色值，补全为6位
  if (hexColor.length < 6) {
    throw new Error('Invalid hex color format');
  }

  // 转换为十六进制数值字符串（前面加'0x'）
  // 如果颜色值包含透明度（长度>=8），则直接转换
  // 否则，对于纯颜色值，我们保持原样转换（但这里不添加透明度）
  return `0x${hexColor}`;
};

//移除过滤img
export const removeImgTagsWithRegex = (htmlString: any) => {
  // 简化处理，只匹配开头的<img ...>标签
  // 注意：这个正则表达式可能不会遇到问题 例如当属性值包含">"时
  return htmlString.replace(/<img\s+[^>]*>/gi, '');
};

//检测图片的长宽比和短边像素是否符合要求
export const isImageValid = (
  width: number,
  height: number,
  aspectRatioMax: number,
  aspectRatioMin: number
) => {
  // 计算长宽比
  const aspectRatio = width / height;

  // 检查长宽比是否在2:5到5:2之间
  const isAspectRatioValid = aspectRatio > aspectRatioMin && aspectRatio < aspectRatioMax;

  // 检查短边是否大于300px
  const isShortSideValid = Math.min(width, height) > 300;

  // 返回两个条件的逻辑与结果
  return isAspectRatioValid && isShortSideValid;
};
