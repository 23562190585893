import { createSlice } from '@reduxjs/toolkit';

interface initState {
  video_timbre: any[];
  videoInfo: {
    docsName: string | undefined;
    videoUrl: string | undefined;
    duration: string;
  };
  imageDerive: string;
  regenerateVideoDerive: any[];
  text2VideoUnionId: string;
  img2VideoUnionId: any[];
}

const initialState: initState = {
  video_timbre: [],
  videoInfo: {
    docsName: undefined,
    videoUrl: undefined,
    duration: '',
  },
  imageDerive: '',
  regenerateVideoDerive: [],
  text2VideoUnionId: '',
  img2VideoUnionId: [],
};

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    // 音色列表数据
    setVideoTimbreList: (state, action) => {
      state.video_timbre = action.payload;
    },
    setVideoInfo: (state, action) => {
      state.videoInfo = action.payload;
    },

    setImageDerive: (state, action) => {
      state.imageDerive = action.payload;
    },

    /** ai视频历史记录中的重新生成list */
    setRegenerateVideoDerive: (state, action) => {
      state.regenerateVideoDerive = action.payload;
    },

    setText2VideoUnionId: (state, action) => {
      state.text2VideoUnionId = action.payload;
    },

    setImg2VideoUnionId: (state, action) => {
      state.img2VideoUnionId = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const {
  setVideoTimbreList,
  setImageDerive,
  setRegenerateVideoDerive,
  setText2VideoUnionId,
  setImg2VideoUnionId,
} = videoSlice.actions;

export default videoSlice.reducer;
